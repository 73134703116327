#wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#footer {
  margin-top: auto;
}

#teconsent a {
  font-size: 0.875rem;
}
